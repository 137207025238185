<template>
  <div id="CompanyIntroduce">
    <MHeader />
    <div class="banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/other/c_i_m.jpg"
        alt=""
        srcset=""
      />
    </div>
    <div class="title">企业介绍</div>
    <div class="intro_box">
     让茶集团创立于2020年，是一家专注“方便健康茶”的现代专业快消品公司。
    </div>
    <div class="intro_box" style="margin-top: 4.133vw">
      旗下拥有无糖茶饮料品牌“让茶”和中式茶包品牌“他山集”。公司以“方便”和“好喝”为经营理念，将传统茶叶以瓶装茶和袋泡茶的现代便捷形式呈现，为年轻群体提供安全、健康、方便的茶饮方式。
    </div>

    <div class="title" style="margin: 6.533vw auto 4vw auto">企业愿景</div>
    <div class="intro_box">让茶陪你每一天</div>
    <div class="title" style="margin: 6.667vw auto 4vw auto">企业使命</div>
    <div class="intro_box">致力于为全球消费者提供方便好喝的茶饮产品</div>

    <div class="p_banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/company/p-banner.jpg"
        alt=""
        srcset=""
      />
    </div>

    <div class="title" style="margin: 3.333vw auto 6.667vw auto">
      达人推荐
    </div>

    <div class="swiper_box">
      <div class="swiper_inside">
        <swiper :options="swiperOption">
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image6.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image7.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image8.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image9.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image10.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
          <swiper-slide>
            <div
              class="banner_box"
              style="
                background-image: url('https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/x_image11.jpg');
              "
            ></div>
            <div class="model"></div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="title" style="margin: 6.533vw auto 4.133vw auto">
      品牌IP合作
    </div>
    <div class="ip_banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/company/ip.png"
        alt=""
        srcset=""
      />
    </div>
    <MobileConncatUs/>
    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import MobileConncatUs from "@/components/MobileConncatUs";
import "swiper/dist/css/swiper.css";
export default {
  name: "CompanyIntroduce",
  components: {
    MHeader,
    MFooter,
    swiper,
    swiperSlide,
    MobileConncatUs
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        watchSlidesProgress: true,
        initialSlide: 1, //默认第二个
        // 设定slide与左边框的预设偏移量（单位px）
        // slidesOffsetBefore: 25,
        // slidesOffsetAfter:25,
        // 设置slide之间的距离（单位px）
        spaceBetween: 12.5,
        centeredSlides: true,
        loop: true,
        autoplay: 3000,
      },
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#CompanyIntroduce {
  margin-top: 18.667vw;
  .banner {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .title {
    width: 23.2vw;
    height: 8vw;
    line-height: 8vw;
    text-align: center;
    margin: 6.667vw auto 3.867vw auto;
    background: #45B035;
    color: black;
    font-weight: 500;
    font-size: 16px;
  }
  .intro_box {
    width: 85.467vw;
    font-size: 13px;
    font-weight: 400;
    margin: 0 auto;
    color: black;
    line-height: 5.6vw;
    text-align: center;
    letter-spacing: 0.667vw;
  }
  .p_banner {
    width: 100vw;
    margin-top: 3.867vw;
    img {
      width: 100%;
    }
  }
  .swiper_box {
    width: 100vw;
    height: 106vw;
    background: #f5f5f5;
    overflow: hidden;
    .swiper_inside {
      width: 100vw;
      height: 106vw;
      margin-top: 13.467vw;
      .swiper-container {
        overflow: visible;
        .swiper-slide {
          width: 38.133vw;
          height: 80.333vw;
          .banner_box {
            width: 38.133vw;
            height: 80.333vw;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 auto;
            /* position: relative; */
          }
        }

        .swiper-slide-active {
          .banner_box {
            width: 38.133vw;
            height: 80.333vw;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0 auto;
            position: relative;
            z-index: 998;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .ip_banner {
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .address_box {
    width: 93.6vw;
    margin: 6vw auto;
    .map {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .content {
      width: 100%;
      height: 11.6vw;
      padding: 2.133vw 2.667vw;
      box-sizing: border-box;
      background: #45B035;
      .content_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tip_item {
          color: white;
          font-size: 10px;
          line-height: 2.133vw;
          height: 4vw;
          img{
              width: 3.467vw;
              height: 3.467vw;
              padding-right:1.333vw;
              vertical-align: middle;
          }
          div{
              margin-top: 2.133vw;
          }
        }
      }
    }
  }
}
</style>
